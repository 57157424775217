/* https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html#comment-4634290908 */
html,
body {
  height: 100%;
}

.viewport {
  height: min(100%, 100vh);
  height: min(100%, calc(var(--vh, 1vh) * 100));
  width: min(100%, 100vw);
  width: min(100%, calc(var(--vw, 1vw) * 100));
}

.vw-100 {
  width: min(100%, 100vw);
  width: min(100%, calc(var(--vw, 1vw) * 100));
}

/* 
.active-area {
  background-color: white;
  opacity: 0.8;
  padding: 5px;
  min-height: calc(100vh - 20px);
  min-height: calc(calc(var(--vh, 1vh) * 100) - 20px);
  max-height: min(calc(calc(var(--vh, 1vh) * 100) - 20px), 700px);
  min-width: min(1000px, calc(100vw - 20px));
  max-width: 1000px;
}

.active-contents {
  min-height: calc(100vh - 30px);
  min-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
  max-height: min(calc(calc(var(--vh, 1vh) * 100) - 30px), 690px);
  min-width: min(980px, calc(100vw - 30px));
  max-width: 990px;
}

.active-contents.flex-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
